import { useQuery } from '@tanstack/react-query';
import { currentClientAtom } from '../recoil/atoms/Clients';
import { useRecoilValue } from 'recoil';
import { ClientTagType } from '../models/ClientTag';
import ClientTagService from '../services/ClientTagService';

const useFetchClientTags = (types: ClientTagType[] = [ClientTagType.UserGroup]) => {
  const currentClient = useRecoilValue(currentClientAtom);
  return useQuery({
    queryKey: ['clientTags', currentClient?.id],
    staleTime: Infinity,
    queryFn: () => {
      return ClientTagService.getAllTags(currentClient!.id, { types: types, pageSize: 9999 }).then((res) => res.data);
    },
  });
};

export default useFetchClientTags;
